import { ref } from 'vue';

export default (key, props) => {
  const storage = ref(null);

  const setStorage = (val) => {
    if (val) {
      storage.value = val;
      localStorage.setItem(key, JSON.stringify(val));
    }
  };
  const get = () => {
    const item = localStorage.getItem(key);

    if (item) {
      storage.value = JSON.parse(item);
    }
  };
  get();

  const clear = () => {};
  return { storage, setStorage, get };
};
