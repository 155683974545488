import introForm from './Intro.vue';
import pictureForm from './Picture.vue';
import workForm from './Work.vue';
import wallForm from './Wall.vue';
export default {
  introForm,
  pictureForm,
  workForm,
  wallForm,
};
