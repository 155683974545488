import { ref, reactive } from 'vue';
import { ROOM_COLOR } from '@/utils/Config';
import ExhibService from '@/services/Exhib';
import { ElMessage } from 'element-plus';

export default (params) => {
  const form = reactive({
    id: null,
    exhibId: '',
    author: '',
    excerpt: '',
    poster_work: '',

    banner_work: '',
    banner: null,
    bgm: null,
    poster: null,
    color: ROOM_COLOR,
  });
  let loading = ref(false);

  const records = reactive({ author: '' });

  const fetcher = () => {
    loading.value = true;
    ExhibService.profile({ ...params }).then((res) => {
      if (res.code !== 1) {
        ElMessage({ message: '載入錯誤', type: 'error' });
      }
      loading.value = false;
      Object.keys(res.data).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(form, key)) {
          form[key] = res.data[key];
          if (key === 'author') {
            records.author = res.data[key];
          }
        }
      });
    });
  };
  const onAuthorChange = () => {
    console.log('change');
  };
  const submit = () => {
    loading.value = true;

    return ExhibService.update({ ...form }).finally(() => {
      loading.value = false;
    });
  };
  return { fetcher, form, submit, loading, onAuthorChange };
};
