<template>
  <div v-loading="loading">
    <El-radio-group v-model="type" @change="onChange">
      <El-radio :label="0">上傳圖片</El-radio>
      <El-radio :label="1">套用版型</El-radio>
    </El-radio-group>
    <div v-show="type === 0">
      <Uploader
        :action="action"
        :type="'image'"
        :accept="'image/png, image/jpeg'"
        :url="uploadImage"
        @update:url="onUploadImage"
        @uploaded="onSuccess({})"
      ></Uploader>
    </div>
    <div v-show="type === 1">
      <div class="w-[400px]">
        <div
          id="canvasRef"
          ref="canvasRef"
          class="border relative overflow-hidden"
          :class="!cover ? 'bg-gray-200' : 'bg-white'"
        >
          <img
            :class="tempSty"
            class="absolute left-[10%] top-[50%] translate-y-[-50%] w-[80%]"
            v-if="cover"
            :src="$filters.uploadUrl(cover)"
            alt=""
          />

          <img class="relative z-10 w-full" :src="imageTemp[temp]" />
          <!-- :src="require(`@/assets/${temp.trim()}`)" -->
          <div
            class="absolute z-10 top-[8.5%] p-2 left-0 min-w-[65%] h-[12%]"
            :class="textSty"
          >
            <p class="font-border text-primary font-bold leading-none">
              {{ title }}
            </p>
            <p v-if="author" class="text-xs leading-none mt-2">
              策展人 : {{ author }}
            </p>
          </div>
        </div>
        <div class="flex items-center mt-4 w-full">
          <el-select
            class="flex-grow w-0"
            v-model="customCoverId"
            placeholder="選擇藏品圖片"
          >
            <el-option
              class="h-auto"
              v-for="item in coverData"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
              <div class="flex py-2 border-b-1 border-gray-500 w-full">
                <div class="w-10 h-10 flex-none">
                  <img
                    class="w-full h-full object-cover"
                    :src="$filters.uploadUrl(item.cover)"
                    alt=""
                  />
                </div>

                <div class="font-bold ml-4 truncate">
                  {{ item.title }}
                </div>
              </div>
            </el-option>
          </el-select>
          <El-Tooltip
            v-model="tip"
            :manual="true"
            class="item"
            effect="dark"
            content="標題或策展人已修改，請重新確認使用。"
            placement="top"
          >
            <El-button class="ml-2" :disabled="!customCoverId" @click="upload"
              >確定使用</El-button
            >
          </El-Tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref, toRefs, watch, reactive, nextTick } from 'vue';

import Uploader from '@/pages/index/components/Uploader.vue';
import html2canvas from 'html2canvas';

import PosterTemp from '@/assets/poster-template.png';
import bannerTemp from '@/assets/banner-template.png';

import useStorage from '@/utils/useStorage';
import {
  ElButton,
  ElSelect,
  ElOption,
  ElRadio,
  ElRadioGroup,
  ElTooltip,
} from 'element-plus';
import form from './form';

export default {
  components: {
    ElButton,
    ElSelect,
    ElOption,
    ElRadio,
    ElRadioGroup,
    Uploader,
    ElTooltip,
  },
  props: {
    service: { type: Function },
    action: { type: String },
    tempSty: { type: String },
    textSty: { type: String },
    title: { type: String },
    temp: { type: String },
    author: { type: String },
    coverId: { type: String },
    image: { type: String },
  },
  emits: ['update:image', 'update:coverId'],
  setup(props, { emit }) {
    const type = ref(0);

    const { title, author, coverId, image, service, temp } = toRefs(props);

    const imageTemp = reactive({
      poster: PosterTemp,
      banner: bannerTemp,
    });

    const selection = inject('selection');

    const onChange = (e) => {
      if (type.value === 0) {
        console.log('0');
      } else {
        console.log('1');
      }
    };

    const uploadImage = ref();

    const onUploadImage = (e) => {
      emit('update:coverId', '');
      emit('update:image', e);
    };

    watch(image, (val) => {
      if (!coverId.value) {
        uploadImage.value = val;
      }
    });

    const customCoverId = ref();

    watch(coverId, (val) => {
      customCoverId.value = val;
      if (val) {
        uploadImage.value = '';
        type.value = 1;
      }
    });
    const onCustomImage = ({ url, id }) => {
      emit('update:image', url);
      emit('update:coverId', id);
    };
    const coverData = computed(() => {
      return selection.data.filter((x) => x.type === 2 || x.type === 3);
    });
    const cover = computed(() => {
      const cur = selection.data.find((x) => x.id === customCoverId.value);
      return cur ? cur.cover : null;
    });
    const loading = ref(false);
    const canvasRef = ref(null);

    const upload = async () => {
      loading.value = true;
      html2canvas(canvasRef.value, { scale: 2, useCORS: true })
        .then(function (canvas) {
          canvas.toBlob(
            (blob) => {
              var formData = new FormData();
              formData.append('file', blob, 'image.jpg');
              service
                .value(formData)
                .then((res) => {
                  if (res.code === 1) {
                    onCustomImage({
                      url: res.data.url,
                      id: customCoverId.value,
                    });
                    onSuccess({
                      title: title.value,
                      author: author.value,
                      coverId: customCoverId.value,
                    });
                  }
                })
                .finally(() => {
                  loading.value = false;
                });
            },
            'image/jpeg',
            0.8
          );
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const { setStorage, storage } = useStorage(temp.value + '_local');

    const onSuccess = (data) => {
      setStorage(data);
    };

    const tip = computed(() => {
      return (
        storage.value &&
        (title.value !== storage.value.title ||
          author.value !== storage.value.author ||
          coverId.value !== storage.value.coverId)
      );
    });

    return {
      onSuccess,
      tip,
      imageTemp,
      onUploadImage,
      uploadImage,
      onChange,
      type,
      loading,
      coverData,
      customCoverId,
      cover,
      canvasRef,
      upload,
    };
  },
};
</script>
