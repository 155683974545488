<template>
  <div class="content">
    <div class="container !px-0 max-w-[500px]" v-loading.lock="loading">
      <El-form label-position="top" :model="form" ref="formRef" :rules="rules">
        <El-form-item label="策展人" prop="author">
          <El-input
            type="textarea"
            rows="3"
            v-model="form.author"
            placeholder="國立歷史博故館 / 張大明"
            @change="onAuthorChange"
          />
          <!-- -->

          <div class="text-gray-500 text-sm">建議字數長度在2到15個字</div>
        </El-form-item>
        <El-form-item label="展覽總說" prop="excerpt">
          <El-input type="textarea" rows="8" v-model="form.excerpt" />
        </El-form-item>
        <!-- <El-form-item label="牆面顏色" prop="color">
          <El-Color-Picker v-model="form.color"> </El-Color-Picker>
        </El-form-item> -->
        <El-form-item label="音樂">
          <Uploader
            :type="'audio'"
            :accept="'.mp3,audio/mpeg3'"
            v-model:url="form.bgm"
            :action="uploadAudioAction"
          ></Uploader>
        </El-form-item>
        <El-form-item label="展場海報">
          <PhotoSelector
            :temp="'poster'"
            :title="exhibState.title"
            :textSty="'top-[10%]'"
            :tempSty="'left-[9%] top-[50%] translate-y-[-50%] w-[82%]'"
            :author="form.author"
            v-model:image="form.poster"
            v-model:coverId="form.poster_work"
            :action="uploadImageAction"
            :service="uploadImageService"
          ></PhotoSelector>
        </El-form-item>
        <El-form-item label="廣告主視覺">
          <PhotoSelector
            :temp="'banner'"
            :textSty="'top-[10.4%]'"
            :tempSty="'left-[15%] top-[50%] translate-y-[-50%] w-[70%]'"
            :title="exhibState.title"
            :author="form.author"
            v-model:image="form.banner"
            v-model:coverId="form.banner_work"
            :action="uploadImageAction"
            :service="uploadImageService"
          ></PhotoSelector>
        </El-form-item>
      </El-form>
    </div>
    <div class="flex justify-center w-full py-10">
      <Editor-Nav />
    </div>
    <El-Dialog
      title="提示"
      v-model="dialog.state.show"
      width="96%"
      @close="dialog.toggle()"
    >
      <component ref="form" :is="dialog.state.component"></component>
    </El-Dialog>
  </div>
</template>

<script>
import { inject, provide, ref, onMounted } from 'vue';
import useProfile from './useProfile';
import PhotoSelector from './_PhotoSelector.vue';
import EditorNav from './_Nav.vue';
import uploadService from '@/services/Upload';
import {
  ElDialog,
  ElButton,
  ElSelect,
  ElOption,
  ElFormItem,
  ElForm,
  ElInput,
  ElColorPicker,
  ElRadio,
  ElRadioGroup,
  ElMessageBox,
} from 'element-plus';
import useDialog from '@/components/useDialog';
import Uploader from '@/pages/index/components/Uploader.vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    PhotoSelector,
    ElDialog,
    ElSelect,
    ElOption,
    ElButton,
    ElRadio,
    ElRadioGroup,
    ElFormItem,
    ElForm,
    ElInput,
    ElColorPicker,
    EditorNav,
    Uploader,
  },
  setup() {
    const exhibId = inject('exhibId');
    const router = useRouter();
    const exhibState = inject('exhibState');

    const formRef = ref(null);
    const dialog = useDialog();
    const { form, fetcher, submit, loading, onAuthorChange } = useProfile({
      id: exhibId,
    });
    const rules = {
      author: [
        { required: true, message: '請填寫內容', trigger: 'blur' },
        {
          min: 2,
          max: 100,
          message: '長度在2到100個字',
          trigger: 'blur',
        },
      ],
      excerpt: [{ required: true, message: '請填寫內容', trigger: 'blur' }],
    };

    const sumbitForm = () => {
      return new Promise((resolve, reject) => {
        formRef.value.validate((valid) => {
          if (valid) {
            submit().then((res) => {
              resolve();
            });
          } else {
            console.log('error submit!!');
            reject();
          }
        });
      });
    };
    provide('saveStep', sumbitForm);

    const resetForm = () => {
      formRef.value.resetFields();
    };
    const posterType = ref(0);
    const bannerType = ref(0);

    const updatePoster = (param) => {
      form.poster = param.url;
      form.poster_work = param.coverId;
    };

    const onPosterChange = (e) => {
      if (posterType.value === 0) {
        console.log('0');
      } else {
        console.log('1');
      }
    };
    const uploadAudioAction = ref(
      process.env.VUE_APP_API_URL + '/exhib/upload_mp3'
    );

    const uploadImageAction = ref(
      process.env.VUE_APP_API_URL + '/upload/exhib'
    );
    const uploadImageService = uploadService.image;

    onMounted(() => {
      if (exhibState.isEditable) {
        fetcher();
      } else {
        ElMessageBox.alert('審核中或通過審核之展覽不可以編輯。', {
          center: true,
          showClose: false,
        }).finally(() => {
          return router.push({ name: 'Home' });
        });
      }
    });

    return {
      onAuthorChange,
      uploadImageAction,
      uploadImageService,
      uploadAudioAction,
      onPosterChange,
      exhibId,
      exhibState,
      updatePoster,
      posterType,
      bannerType,
      form,
      dialog,
      rules,
      sumbitForm,
      formRef,
      resetForm,
      loading,
    };
  },
};
</script>
