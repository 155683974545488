import Axios from './http';

export default class upload {
  /**
   * @description 查询邀請函列表
   * @return {HttpResponse} result
   */

  static async chip(params) {
    return Axios('/upload/chip', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async image(params) {
    return Axios('/upload/exhib', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
  static async audio(params) {
    return Axios('/exhib/upload_mp3', {
      method: 'post',
      responseType: 'json',
      data: params,
    });
  }
}
